




















import Vue from 'vue'
import Component from 'vue-class-component'
import { User } from '@/types'

import { SubscriptionStatus } from '@/api'
import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import ListItem from '@/components/Layout/ListItem.vue'

@Component({
  components: {
    Content,
    Toolbar,
    ListItem
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    }
  }
})
export default class SubscriptionActions extends Vue {
  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscriptionDetails
  }

  public back () {
    const currentUser = this.$store.state.currentUser
    if (currentUser && currentUser.subscriptionDetails.subscriptionStatus === 'Expires') {
      this.$router.push({ name: 'dashboard' })
    } else {
      this.$router.push({ name: 'subscription' })
    }
  }

  SubscriptionStatus = SubscriptionStatus;
}
